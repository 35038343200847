<script setup>
defineProps({
  listing: {
    type: Object,
    required: true,
  }
})
const isOpen = ref(false);
</script>
<template>
  <button type="button" items @click="isOpen = true"
    class="w-full py-2.5 rounded-lg bg-gray-200 text-indigo-900">
    <i class="fa-solid fa-phone me-2"></i> Call
  </button>

  <w-modal v-model="isOpen" :transition="false" class="w-full px-0">
    <div class="p-3 text-center flex flex-col gap-3">
      <h3 class="text-gray-900 text-2xl mb-6">{{ $t('client.contact_us') }}</h3>
      <p class="text-gray-500 text-sm mb-6">{{ listing.shop.name }}</p>
      <div class="text-indigo-700 text-3xl text-center flex justify-center items-center"><span class="text-xs text-gray-500 me-3">{{ $t('client.work_mobile') }}</span><a :href="`tel:+${listing.shop.country_code}${listing.shop.mobile}`">{{ listing.shop.formatted_mobile }}</a>
      </div>
      <div v-if="listing.user.show_mobile_in_listings" class="text-indigo-700 text-3xl text-center flex justify-center items-center"><span class=" text-xs text-gray-500 me-2">{{ $t('client.agent_mobile') }}</span><a :href="`tel:+${listing.user.country_code}${listing.user.mobile}`">{{ listing.user.formatted_mobile }}</a>
      </div>
      <div class="text-indigo-700 text-3xl" v-if="listing.user.show_whatsapp_in_listings && listing.user.formatted_whatsapp">
          <span class="text-xs text-gray-500 me-3">{{ $t('client.whatsapp') }}</span>
          <NuxtLink :to="listing.user.whatsapp_link" target="_blank">{{ listing.user.formatted_whatsapp }}</NuxtLink>
      </div>
      <h6 class="text-sm mb-3 border-y border-gray-300 py-3">
        <span class="text-xs text-gray-500 me-3">{{ $t('client.agent') }}:</span>
        {{ `${listing.user.first_name}
        ${listing.user.last_name}` }}
      </h6>
      <div class="mt-4 text-gray-600">
        {{ $t('client.property_reference') }}
        <br> <span class="font-medium text-gray-900"># {{ listing._id }}</span>
        <br> {{ $t('client.when_calling_us') }}
      </div>
    </div>
  </w-modal>
</template>